import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * To add a feature flag:
 * - add the flag to the FEATURE_FLAGS array
 * - add it to the type
 * - give it a default value
 */

const FEATURE_FLAGS = <const>[
  "legacyMessagesView",
  "integrations",
  "sinks",
  "enableFifo",
];
export type IFeatureFlag = typeof FEATURE_FLAGS[number];
export type IFeatureFlags = {
  [flag in IFeatureFlag]: boolean;
};

export function isFlag(flagName: string): flagName is IFeatureFlag {
  return (FEATURE_FLAGS as readonly string[]).includes(flagName);
}

const initialState: IFeatureFlags = {
  legacyMessagesView: false,
  integrations: false,
  sinks: false,
  enableFifo: false,
};

const slice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    enableFlag(state: IFeatureFlags, action: PayloadAction<IFeatureFlag>) {
      state[action.payload] = true;
    },
    disableFlag(state: IFeatureFlags, action: PayloadAction<IFeatureFlag>) {
      state[action.payload] = false;
    },
  },
});

export const { enableFlag, disableFlag } = slice.actions;
export default slice.reducer;
