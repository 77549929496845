import {
  Divider,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { MoreVert } from "@material-ui/icons";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { EndpointSecretOut } from "svix/dist/openapi";

import { humanize } from "@svix/common/utils";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
  BreadcrumbItemWithId,
} from "@svix/common/widgets/PageToolbar";

import { getSvix } from "src/api";
import { SinksApi, TransformationIn } from "src/api/sinks";
import { StreamSinkIn, StreamSinkOut, StreamSinkStatus } from "src/api/streamSinks";
import { routeResolver } from "src/App";
import { useAppQuery } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { SinkDetails } from "src/screens/Stream/Sink/SinkDetails";
import StreamSinkEventTypes from "./EventTypes";
import { SigningSecretProperty } from "../../properties/SigningSecret";

export default function StreamSink() {
  const history = useHistory();
  const toast = useToast();
  const user = useAppSelector((state) => state.auth.user)!;
  const queryClient = useQueryClient();

  const sinkId = useParams<{ sinkId: string }>().sinkId;
  const queryKey = ["endpoints", "stream", "sinks", sinkId];
  const { data: sink } = useAppQuery(queryKey, async () => {
    const sv = getSvix();
    const api = new SinksApi(sv);
    return api.getStreamSink(user.app.id, sinkId);
  });

  const { data: transformation } = useAppQuery(
    ["endpoints", "stream", "sinks", sinkId, "transformation"],
    async () => {
      const sv = getSvix();
      const api = new SinksApi(sv);
      return api.getStreamSinkTransformation(user.app.id, sinkId);
    }
  );

  const [pauseDialogOpen, setPauseDialogOpen] = useBoolean(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useBoolean(false);
  const deleteSink = async () => {
    const sv = getSvix();
    const api = new SinksApi(sv);
    await api.deleteStreamSink(user.app.id, sinkId);
    toast({
      title: "Endpoint deleted",
      status: "success",
    });
    history.push(routeResolver.getRoute("endpoints"));
  };

  const onPauseSink = async () => {
    await onSetSinkStatus(StreamSinkStatus.PAUSED);
    setPauseDialogOpen.off();
    toast({
      title: "Endpoint paused",
      status: "success",
    });
  };

  const onEnableSink = async () => {
    await onSetSinkStatus(StreamSinkStatus.ENABLED);
    toast({
      title: "Endpoint enabled",
      status: "success",
    });
  };

  const onSetSinkStatus = async (status: StreamSinkStatus) => {
    const sv = getSvix();
    const api = new SinksApi(sv);
    await api.updateStreamSink(user.app.id, sinkId, {
      ...sink,
      status,
    });
    queryClient.invalidateQueries(queryKey);
  };

  const onUpdateSink = async (sink: StreamSinkOut) => {
    const sv = getSvix();
    const api = new SinksApi(sv);
    return await api.updateStreamSink(user.app.id, sinkId, sink as StreamSinkIn);
  };

  const onUpdateTransformation = async (transformation: TransformationIn) => {
    const sv = getSvix();
    const api = new SinksApi(sv);
    return api.updateStreamSinkTransformation(user.app.id, sinkId, transformation);
  };

  if (!sink || !transformation) {
    // FIXME STREAM_APP_PORTAL: Add skeleton for loading indicator
    return <LoadingIndicator />;
  }

  return (
    <>
      <MetaTitle path={[sink.uid ?? humanize(sinkId), "Endpoints", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("endpoints")}>
            Endpoints
          </BreadcrumbItem>
          <BreadcrumbItemWithId identifier={sinkId} uid={sink.uid ?? undefined} />
        </Breadcrumbs>

        <Spacer />

        <Menu placement="bottom-end">
          <MenuButton as={IconButton} variant="rounded" data-cy="options-button">
            <MoreVert />
          </MenuButton>
          <MenuList data-cy="options-menu">
            {sink.status === StreamSinkStatus.ENABLED && (
              <MenuItem onClick={setPauseDialogOpen.on}>Pause</MenuItem>
            )}
            {sink.status !== StreamSinkStatus.ENABLED && (
              <MenuItem onClick={onEnableSink}>Enable</MenuItem>
            )}
            <MenuItem textColor="text.danger" onClick={setDeleteDialogOpen.on}>
              Delete
            </MenuItem>
          </MenuList>
        </Menu>
      </PageToolbar>

      <ConfirmationDialog
        title="Deletion confirmation"
        isOpen={deleteDialogOpen}
        onCancel={() => setDeleteDialogOpen.off()}
        onOk={deleteSink}
        labelOk="Delete"
        colorScheme="red"
      >
        Are you sure you would like to permanently delete this endpoint?
      </ConfirmationDialog>

      <ConfirmationDialog
        title="Pause endpoint"
        isOpen={pauseDialogOpen}
        onCancel={() => setPauseDialogOpen.off()}
        onOk={onPauseSink}
        labelOk="Pause"
      >
        Are you sure you would like to pause this endpoint?
      </ConfirmationDialog>

      <SinkDetails
        sink={sink}
        transformationProps={{
          transformation,
          onUpdateTransformation,
        }}
        queryKey={queryKey}
        onUpdateSink={onUpdateSink}
        SinkProperties={<StreamSinkProperties sink={sink} queryKey={queryKey} />}
      />
    </>
  );
}

function StreamSinkProperties({
  sink,
  queryKey,
}: {
  sink: StreamSinkOut;
  queryKey: string[];
}) {
  const user = useAppSelector((state) => state.auth.user)!;
  const sinkId = useParams<{ sinkId: string }>().sinkId;

  const onRotateSecret = async () => {
    const sv = getSvix();
    const api = new SinksApi(sv);
    return api.rotateStreamSinkSigningSecret(user.app.id, sinkId);
  };

  const onGetSecret = async () => {
    const sv = getSvix();
    const api = new SinksApi(sv);
    const res = await api.getStreamSinkSigningSecret(user.app.id, sinkId);

    // Rotate secret if it doesn't exist
    if (res.key === null) {
      await onRotateSecret();
    }

    return api.getStreamSinkSigningSecret(
      user.app.id,
      sinkId
    ) as Promise<EndpointSecretOut>;
  };

  return (
    <>
      <StreamSinkEventTypes sink={sink} queryKey={queryKey} />
      <Divider />
      <SigningSecretProperty onGetSecret={onGetSecret} onRotateSecret={onRotateSecret} />
    </>
  );
}
